import React from 'react'
import Main from './Main'
import LogoLabeller from '../assets/img/logo-icon.svg'

const MainLayout = () => {

    return (
        <div id='MainLayout' className='row-offcanvas row-offcanvas-left'>
            <nav className='sidebar-offcanvas sidebar'>
                <a href='/' className='navbar-brand'>
                    <img src={LogoLabeller} class="m-auto d-block img-fluid" alt='' />
                </a>
                <ul className='nav flex-column align-self-end'>
                    <li className='nav-item mx-auto'>
                        <a href='/' target='_self' className='nav-link active'>
                            <div className='pt-1'>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lungs" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512" class="svg-inline--fa fa-lungs fa-w-20 fa-2x">
                                    <path fill="currentColor" d="M636.11 390.15C614.44 308.85 580.07 231 534.1 
                        159.13 511.98 124.56 498.03 96 454.05 96 415.36 96 384 125.42 384 
                        161.71v60.11l-32.88-21.92a15.996 15.996 0 0 1-7.12-13.31V16c0-8.84-7.16-16-16-16h-16c-8.84 
                        0-16 7.16-16 16v170.59c0 5.35-2.67 10.34-7.12 13.31L256 221.82v-60.11C256 
                        125.42 224.64 96 185.95 96c-43.98 0-57.93 28.56-80.05 63.13C59.93 231 25.56 
                        308.85 3.89 390.15 1.3 399.84 0 409.79 0 419.78c0 61.23 62.48 105.44 125.24 
                        88.62l59.5-15.95c42.18-11.3 71.26-47.47 71.26-88.62v-87.49l-85.84 57.23a7.992 
                        7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09L320 235.23l167.59 
                        111.72a7.994 7.994 0 0 1 2.22 11.09l-8.88 13.31a7.994 7.994 0 0 1-11.09 2.22L384 
                        316.34v87.49c0 41.15 29.08 77.31 71.26 88.62l59.5 15.95C577.52 525.22 640 481.01 
                        640 419.78c0-9.99-1.3-19.94-3.89-29.63z"
                                        class=""></path>
                                </svg>
                            </div>
                        </a>
                    </li>
                </ul>
                <ul className='nav flex-column bottom'>
                    <li className='nav-item mx-auto'>
                        <a href='/' target='_self' className='nav-link'>
                            <div className='pt-2'>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-lock" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512" class="svg-inline--fa fa-user-lock fa-w-20 fa-2x">
                                    <path fill="currentColor" d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 
                        8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 
                        48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 
                        32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 
                        32 0 0 1 64 0z" class=""></path>
                                </svg>
                                <span class="text">Change Password</span>
                            </div>
                        </a>
                    </li>
                    <li
                        class="nav-item mx-auto"
                    // onClick={authService.logout()}
                    >
                        <a href="/" target="_self" class="nav-link">
                            <div class="pt-2">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512" class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-2x"><path fill="currentColor" d="M497 273L329 441c-15 15-41 
                        4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 
                        436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 
                        0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z" class=""></path>
                                </svg>
                                <span class="text">Logout</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
            <Main />
        </div>
    )
}

export default MainLayout
