import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { challengerService } from '../services/challenger.service';

const getStyle = (position) => {
  let style = 'font-size: 1rem';
  if (position === 1) {
    style = 'font-size: 1.33em; padding-left: 0.25rem; padding-right: 0.25rem;';
  } else if (position === 2) {
    style = 'font-size: 1.17em; padding-left: 0.15rem; padding-right: 0.15rem;';
  } else if (position === 3) {
    style = 'font-size: 1em;';
  }
  return style;
}

const StyledTr = styled.tr`
  &:hover {
    color: #FFC848 !important;
  }
`;

const StyledTd = styled.td`
  align-items: center !important;
  vertical-align: middle !important;
  &:hover {
    color: #FFC848;
  };
  padding: 0.5rem 0.75rem !important;
`;

const StyledTdPos = styled.td`
  align-items: center !important;
  vertical-align: middle !important;
  &:hover {
    color: #FFC848;
  };
  padding: 0.5rem 0.75rem !important;
  width: 6rem;
`;

const StyledTdName = styled.td`
  align-items: center !important;
  vertical-align: middle !important;
  &:hover {
    color: #FFC848;
  };
  padding: 0.5rem 0.75rem !important;
  text-align: left !important;
  width: 25rem;
  font-weight: 600;
`;

const StyledBadge = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.position <= 1 ? '2.2em' : props.position <= 2 ? '2em ' : props.position <= 3 ? '1.8em' : '1.5em'};
  height: ${props => props.position <= 1 ? '2.2em' : props.position <= 2 ? '2em ' : props.position <= 3 ? '1.8em' : '1.5em'};
  border-radius: ${props => props.position <= 3 ? '2em' : '1.5em'};
  background-color: ${props => {
    if (props.position > 6) return 'orangered';
    else if (props.position > 3) return '#FFC848';
    else return '#28a745';
  }};
  color: #000;
  font-weight: 600 !important;
`;

const StyledStatusBadge = styled.div`
  color: ${props => (props.status === 'Completed' ? '#28a745' : '#FFC848')};
  height: ${props => props.position <= 1 ? '2.2em' : props.position <= 2 ? '2em ' : props.position <= 3 ? '1.8em' : '1.5em'};
  border-radius: 1.5em / 0.75em;
`;

const StyledSpan = styled.span`
  color: ${props => {
    if (props.averageAccuracy < 70) return 'orangered';
    else if (props.averageAccuracy < 80) return '#FFC848';
    else return '#28a745';
  }};
`;

const StyledDiv = styled.div`
  font-size: ${props => {
    if (props.position === 1) return '1.6em';
    else if (props.position === 2) return '1.5em';
    else if (props.position === 3) return '1.4em';
    else return '1.3rem';
  }};
`;

const StyledDivPos = styled.div`
  font-size: ${props => {
    if (props.position === 1) return '1.8em';
    else if (props.position === 2) return '1.7em';
    else if (props.position === 3) return '1.6em';
    else return '1.4rem';
  }};
  font-weight: 900;
  color: ${props => {
    if (props.position > 6) return 'orangered';
    else if (props.position > 3) return '#FFC848';
    else return '#28a745';
  }};
`;

const msToTime = (ms) => {
  let seconds = Math.floor((ms / 1000) % 60);
  let minutes = Math.floor((ms / (1000 * 60)) % 60);
  let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return `${parseInt(hours) ? `${parseInt(hours)} hrs ` : ''}${parseInt(minutes) ? `${parseInt(minutes)} min ` : ''}${seconds} sec`;
}

const LeaderTable = () => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await challengerService.getAll();
        console.table(data);
        if (data && data.length > 0) {
          const leaderboardData = data.map(item => {
            const averageAccuracy = Number(item.scores.accuracy) || 0;
            const totalImages = Number(item.scores.images) || 0;
            const totalTime = Number(item.scores.time) || 0;
    
            const averageTime = totalImages ? totalTime / totalImages : 0;
    
            return { ...item, averageAccuracy, totalTime: msToTime(totalTime), averageTime: msToTime(averageTime) };
          }).sort((a, b) => b.averageAccuracy - a.averageAccuracy || a.averageTime - b.averageTime);
    
          leaderboardData.forEach((item, index) => {
            item.position = index + 1;
          });
    
          setRecords(leaderboardData);
          console.log('data', leaderboardData)
        }
      } catch (error) {
        console.error(`Failed to fetch data: ${error}`);
      }
    };
  
    fetchData();
  
    const interval = setInterval(fetchData, 1000);
  
    return () => clearInterval(interval);
  }, []);
  

  return (
    <Table style={{ color: '#fff' }} striped hover>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th class="text-left">Name</th>
          <th>Status</th>
          <th>Images</th>
          <th>Accuracy</th>
          <th>Average</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {records.map((item) => (
          <StyledTr key={item.id}>
            <StyledTdPos>
              <StyledDivPos position={item.position}>
                {item.position}
              </StyledDivPos>
            </StyledTdPos>
            <StyledTdName>
              <StyledDiv position={item.position}>
                {item.name}
              </StyledDiv>
            </StyledTdName>
            <StyledTd>
              <StyledStatusBadge length={records.length} position={item.position}>
                <StyledDiv position={item.position}>
                  {item.status === 'Completed' ? 'Completed' : 'In progress'}
                </StyledDiv>
              </StyledStatusBadge>
            </StyledTd>
            <StyledTd>
              <StyledDiv position={item.position}>
                {item.scores.images}
              </StyledDiv>
            </StyledTd>
            <StyledTd>
              <StyledSpan averageAccuracy={item.averageAccuracy}>
                <StyledDiv position={item.position}>
                  {item.averageAccuracy.toFixed(2)}%
                </StyledDiv>
              </StyledSpan>
            </StyledTd>
            <StyledTd>
              <StyledDiv position={item.position}>
                {item.averageTime}
              </StyledDiv>
            </StyledTd>
            <StyledTd>
              <StyledDiv position={item.position}>
                {item.totalTime}
              </StyledDiv>
            </StyledTd>
          </StyledTr>
        ))}
      </tbody>
    </Table>
  );
}

export default LeaderTable;
