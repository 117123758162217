export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && text !== 'Unauthorized' && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    if (response.status === 204) {
      return null
    }

    return data
  })
}
