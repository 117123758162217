import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Authorised from "./components/Authorised";
import "./assets/styles/custom-main.scss";
import "./assets/styles/custom.scss";
import "./assets/styles/extras.scss";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Authorised />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

