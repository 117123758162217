import React, { useState } from "react";
import LeaderTable from "./LeaderTable";

const Main = () => {
    const [isLoadingCount, setIsLoadingCount] = useState(false);
    return (
        <main role="main" className="main">
            <div id="MainView">
                {/* <nav className="navbar px-4 py-3 navbar-dark navbar-expand-lg">
                    <li className="form-inline navy-fields">
                        <form className="form-inline">
                            <div role="group" className="input-group rounded-pill">
                                <div className="input-group-prepend">
                                    <div className="input-group-text navy-fields form-control prepend">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="search"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            className="svg-inline--fa fa-search fa-w-16"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                className=""
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="__BVID__25"
                                    placeholder="Search"
                                    aria-label="Search"
                                />
                                <div
                                    className={`input-group-append`}
                                    style={{ width: "100px" }}
                                >
                                    <div className="input-group-text navy-fields form-control append">
                                        Remaining Images
                                        {isLoadingCount ? (
                                            <small
                                                className="ml-1 spinner-border spinner-border-sm text-primary"
                                                role="status"
                                            ></small>
                                        ) : (
                                            <span className="ml-1 badge badge-light">
                                                {0}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </li>
                </nav> */}
                <div className="px-4 inner-container">
                    <div className="row inner-row">
                        <div className={`div2 col-12`}>
                            <div className="card h-100 bg-dark-navy custom border-0">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-4 d-flex align-items-center mb-10">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="chart-area"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                className="mr-1 svg-inline--fa fa-chart-area fa-w-16 fa-lg"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z"
                                                    className=""
                                                ></path>
                                            </svg>
                                            <span className="color-white mt-1 ml-1">Leaderboard</span>
                                        </div>
                                        {/* <div className="col text-right mb-10">
                                            <h6 className="color-white mb-0">Project 1: Chest X-Rays</h6>
                                        </div> */}
                                        <LeaderTable/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}

export default Main
